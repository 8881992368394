import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { LogoImgAttraction } from '../../Files/Logos/LogoImgAttraction.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Univers = (props) => {
    const hosting = HostBackend();
    const [univers, setUnivers] = useState([]);
    const [attractions, setAttractions] = useState([]);
    const id = parseInt(props.id);
    useEffect(() => {
        const fetchData = async () => {
            const datasUnivers = await fetch(`${hosting}/univers?id=${id}`);
            const univers = await datasUnivers.json();
            setUnivers(univers);
            const datasAttractions = await fetch(`${hosting}/attractions_by_univers?id=${id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
    }, [hosting, id]);
    const categories = FilterCategory('univers', props.att, id);
    const str_total = GetStringTotal('attraction', attractions.length);
    const collection = [];
    for (let i = 0; i < attractions.length; i++) {
        collection.push({
            slug: attractions[i].aslug,
            name: attractions[i].aname,
            href: attractions[i].aroute,
            images: LogoImgAttraction('icon', attractions[i].aslug)
        });
    }
    return (
        <div className="univers">
            <Carrousel label="univers" datas={collection} total={attractions.length} />
            <BreadCrumb />
            <section className="infos">
                {univers.map((item, i) => {
                    return (
                        <div className="bloc-infos" key={`b-${i}`}>
                            <BlockLogo label="univers" item={item} />
                            <BlockCollection label="categories" datas={categories} attractions={attractions} />
                            <BlockCollection label="univers" datas={attractions} />
                        </div>
                    )
                })}
                {univers.map((item, i) => {
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle key={`i-${i}`} label="univers" item={item} total={str_total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
        </div>
    )
}
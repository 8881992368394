import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { THead } from '../../Components/Admin/Collection/THead.js';
import { Entities } from '../../Components/Admin/Collection/Entities.js';
import { Attributes } from '../../Components/Admin/Collection/Attributes.js';
import { ButtonLogout } from '../../Components/Admin/Form/Buttons/ButtonLogout.js';
import { ButtonInsert } from '../../Components/Admin/Form/Buttons/ButtonInsert.js';
import { Selector } from '../../Components/Admin/Collection/Selector.js';
import { GetEntitySelector } from '../../Components/Admin/Functions/GetEntitySelector.js';

export const Collection = () => {
    const hash = sessionStorage.getItem('token');
    let { entity } = useParams();
    let { sort } = useParams();
    let selector = GetEntitySelector(entity);
    let sorter = sort ? `/sortby?sort=${sort}` : '';
    const hosting = HostBackend();
    const [chooser, setChooser] = useState([]);
    const [collection, setCollection] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataChooser = await fetch(`${hosting}/admin/chooser/${selector}`);
            const chooser = await dataChooser.json();
            setChooser(chooser);
            const dataCollection = await fetch(`${hosting}/admin/collection/${entity}${sorter}`);
            const collection = await dataCollection.json();
            setCollection(collection);
        };
        fetchData();
    }, [hosting, entity, sorter, selector, sort]);
    if (hash) {
        return (
            <div className="admin">
                <Selector entity={entity} chooser={chooser} sort={sort} />
                <ButtonInsert entity={entity} />
                <ButtonLogout />
                <div className="title">
                    <div>{`ADMIN: ${entity} (${collection.length})`}</div>
                </div>
                <div className="admin-list">
                    <THead uri={entity} />
                    <Entities entity={entity} datas={collection} />
                    <Attributes entity={entity} datas={collection} />
                </div>
            </div>
        )
    } else {
        return(<></>)
    }
}
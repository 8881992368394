import { useEffect } from "react";
/* datas */
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterOrigins } from "../../Components/Public/Filters/FilterOrigins.js";
import { FilterCategory } from "../../Components/Public/Filters/FilterCategory.js";
import { LogoImgAttraction } from "../../Files/Logos/LogoImgAttraction.js";
/* document */
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { Jumbotron } from '../../Components/Public/Document/Jumbotron.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { AnimCarrousel } from '../../Components/Public/Functions/AnimCarrousel.js';

export const Home = (props) => {
    const parks = props.parks;
    const attractions = props.att;
    const caracters = props.car;
    const origins = FilterOrigins('home', props.att);
    const categories = FilterCategory('home', props.att);
    const datas = [
        { total: GetStringTotal('parc', parks.length) },
        { total: GetStringTotal('univers', parks[0] ? parks[0].univers.length + parks[1].univers.length : 0) },
        { total: GetStringTotal('attraction', attractions.length) },
        { total: GetStringTotal('personnage', caracters.length) },
        { total: GetStringTotal('origine', categories.length) },
    ]
    const collection = [];
    for (let i = 0; i < attractions.length; i++) {
        collection.push({
            slug: attractions[i].aslug,
            name: attractions[i].aname,
            href: attractions[i].aroute,
            images: LogoImgAttraction('icon', attractions[i].aslug)
        });
    }
    useEffect(() => { AnimCarrousel(); });
    return (
        <div className="home">
            <Carrousel label="home" datas={collection} total={attractions.length} />
            <Jumbotron datas={datas} title={'Disneyland'} sub={'Paris'} />
            <section className="infos">
                <div className="block-parks">
                    {parks.map((item, i) => {
                        let univers = null;
                        let attraction = null;
                        if (item.pid === 1) {
                            univers = item.univers;
                            attraction = FilterAttractions(attractions, item.pid);
                        } else {
                            univers = item.univers;
                            attraction = FilterAttractions(attractions, item.pid);
                        }
                        return (
                            <div className="bloc-infos" key={`i-${i}`}>
                                <BlockLogo label="home" item={item} />
                                <BlockCollection label="home" datas={univers} attractions={attraction} />
                            </div>
                        )
                    })}
                </div>
                <div className="block-collections">
                    <div className="bloc-infos">
                        <BlockCollection label="categories" datas={categories} attractions={attractions} />
                    </div>
                    <div className="bloc-infos">
                        <BlockCollection label="origins" datas={origins} attractions={attractions} caracters={caracters} />
                    </div>
                </div>
            </section>
        </div>
    )
}

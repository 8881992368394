import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Outlinks } from '../../Pages/Public/Outlinks.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteOutlinks = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/liens" element={<Outlinks />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
import { CreatedAt } from '../Document/CreatedAt.js';
import { BlockLink } from './BlockLink.js';

export const BlockTitle = (props) => {
    const item = props.item;
    const totalAndLabel = props.total ? props.total : '';
    const link_jumba_jookiba = (slug) => {
        if (slug === 'jumba-jookiba') return (
            <BlockLink href={'/experiences/stitch'} name={'Expérences Stitch'} link={true} />
        )
    };
    switch (props.label) {
        case 'park':
        case 'origins':
        case 'categories':
        case 'caracters':
        case 'outlinks':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.name}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                    </div>
                </div>
            )
        case 'univers':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.uname}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                        <BlockLink href={item.proute} name={item.pname} link={true} />
                    </div>
                </div>
            )
        case 'category':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.cname}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                        <BlockLink href={'/categories'} name={'Catégories'} link={true} />
                    </div>
                </div>
            )
        case 'origin':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.name}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                        <BlockLink href={'/origines'} name={'Origines'} link={true} />
                    </div>
                </div>
            )
        case 'caracter':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.name}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                        <BlockLink href={'/personnages'} name={'personnages'} link={true} />
                        <BlockLink href={'/origine/walt-disney-imagineering'} name={'Walt Disney Imagineering'} link={true} />
                        {link_jumba_jookiba(item.slug)}
                    </div>
                </div>
            )
        case 'attraction':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.aname}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.author}</div>
                        <BlockLink href={`/categorie/${item.cslug}`} name={item.cname} link={true} />
                        <BlockLink href={`/origine/${item.oslug}`} name={item.oname} link={true} />
                    </div>
                </div>
            )
        case 'experiment':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <div className="title-name">{item.name}</div>
                        <div className="title-total-label">{totalAndLabel}</div>
                        <div className="title-author">{item.authors.join(', ')}</div>
                        {item.authors.map((author, i) => {
                            return (
                                <BlockLink href={`${item.deviantart}${author}`} name={author} link={true} key={`i-${i}`} />
                            )
                        })}
                    </div>
                </div>
            )
        default:
            return (
                <></>
            )
    }
}
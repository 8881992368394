import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { FormAdminUpdate } from '../../Components/Admin/Form/FormAdminUpdate.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const Update = () => {
    let { entity } = useParams();
    let { id } = useParams();
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasUpdate = await fetch(`${hosting}/admin/form/${entity}?id=${id}`);
            const datas = await datasUpdate.json();
            setDatas(datas);
        };
        fetchData();
    }, [hosting, entity, id]);
    window.addEventListener('load', (ev) => {
        AutoComplete();
    });
    return (
        <div className="admin">
            <FormAdminUpdate entity={entity} datas={datas} />
            <a className="back" href={`/admin/entity/collection/${entity}`} alt="back" title="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}
import { HostBackend } from '../../../../Routes/HostBackend.js';

export const ButtonDelete = (props) => {
    const hosting = HostBackend();
    const entity = props.entity;
    return (
        <form className="form-btn" action={`${hosting}/admin/delete/${entity}`} method="POST">
            <input type="hidden" name="id" defaultValue={props.id} />
            <input type="submit" name="delete" value="Delete" className="button" />
        </form>
    )
}
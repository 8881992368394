import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { CountDatas } from '../../Components/Public/Functions/CountDatas.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Origins = (props) => {
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const bodyDatas = await fetch(`${hosting}/origins`);
            const datas = await bodyDatas.json();
            setDatas(datas);
        };
        fetchData();
    }, [hosting]);
    let items = {}
    if (datas[0]) {
        items = {
            created: '1937-01-01',
            author: 'Walt Disney Imagineering',
            name: 'Films classiques d\'animations\ndes studios Disney',
            description: datas[0].description,
            link_name: datas[0].link_name,
            link_href: datas[0].link_href,
        }
    };
    const total = GetStringTotal('origine', props.ori.length);
    const origins = props.ori ? props.ori : [];
    const tt_origin = CountDatas('origin', props.att);
    const tt_caracter = CountDatas('caracter',props.car);
    return (
        <div className="origins">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {origins.map((item, i) => {
                        return (
                            <BlockLogo label="origins" item={item} key={`i-${i}`} tt_ori={tt_origin[item.oslug]} tt_cat={tt_caracter[item.oslug]} />
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="origins" item={items} total={total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
        </div>
    )
}

import { GetIconCode } from '../Functions/GetIconCode';

export const BlockLink = (props) => {
    if (props.total && props.total > 0) {
        return (
            <a className="title-link" href={props.href} alt={props.name} title={props.name}>
                <div className="code">{props.total}</div>
                <div className="name">{props.label}</div>
            </a>
        )
    } else if (props.date) {
        return (
            <a className="title-link" href={props.href} alt={props.name} title={props.name}>
                <div className="code"><i className={GetIconCode('LK')}></i></div>
                <div className="name">
                    <div>{props.name}</div>
                    <div>{props.date}</div>
                </div>
            </a>
        )
    } else if (props.link === true) {
        return (
            <a className="title-link" href={props.href} alt={props.name} title={props.name}>
                <div className="code"><i className={GetIconCode('LK')}></i></div>
                <div className="name">{props.name}</div>
            </a>
        )
    } else {
        return (
            <></>
        )
    }
}
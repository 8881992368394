import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { FilterParks } from '../../Components/Public/Filters/FilterParks.js';
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { LogoUnivers } from '../../Files/Logos/LogoUnivers.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Park = (props) => {
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasPark = await fetch(`${hosting}/park?id=${id}`);
            const datas = await datasPark.json();
            setDatas(datas);
        };
        fetchData();
    }, [hosting, id]);
    const park = FilterParks(props.att, id);
    const univers = park[0].univers;
    const attractions = FilterAttractions(props.att, id);
    const categories = FilterCategory('park', props.att, id);
    const str_total = GetStringTotal('attraction', attractions.length);
    const collection = [];
    let total = 0;
    for (let i = 0; i < univers.length; i++) {
        collection.push({
            slug: univers[i].uslug,
            name: univers[i].uname,
            href: univers[i].uroute,
            images: LogoUnivers(univers[i].uslug)
        });
        total = univers.length;
    }
    return (
        <div className="park">
            <Carrousel label="park" datas={collection} total={total} />
            <BreadCrumb />
            <section className="infos">
                {park.map((item, i) => {
                    return (
                        <div className="bloc-infos" key={`i-${i}`}>
                            <BlockLogo label="park" item={item} />
                            <BlockCollection label="park" datas={univers} attractions={attractions} />
                            <BlockCollection label="categories" datas={categories} attractions={attractions}/>
                            <BlockCollection label="attractions" datas={attractions} />
                        </div>
                    )
                })}
                {datas.map((item, i) => {
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle label="park" item={item} total={str_total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
        </div>
    )
}
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Origin } from '../../Pages/Public/Origin.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteOrigin = () => {
    const hosting = HostBackend();
    const [navOrigins, setNavOrigins] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavOrigins = await fetch(`${hosting}/nav_origins`);
            const navOrigins = await dataNavOrigins.json();
            setNavOrigins(navOrigins);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                {navOrigins.map((el, i) => {
                    return (<Route path={el.oroute} element={<Origin id={el.oid} />} key={`m-${i}`} />)
                })}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
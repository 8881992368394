import { LogoImgDefault } from './LogoImgDefault.js';
import { AMillionSplashesOfColours } from '../Attractions/AMillionSplashesOfColours.js';
import { AdventureIsle } from '../Attractions/AdventureIsle.js';
import { AliceEtLaReineDeCoeur } from '../Attractions/AliceEtLaReineDeCoeur.js';
import { AliceSCuriousLabyrinth } from '../Attractions/AliceSCuriousLabyrinth.js';
import { AnimationAcademy } from '../Attractions/AnimationAcademy.js';
import { AnimationCelebrationFrozen } from '../Attractions/AnimationCelebrationFrozen.js';
import { AraucariaAraucana } from '../Attractions/AraucariaAraucana.js';
import { ArbreASorcieres } from '../Attractions/ArbreASorcieres.js';
import { ArbreAuxQuaranteEcus } from '../Attractions/ArbreAuxQuaranteEcus.js';
import { ArbreNuageNiwaki } from '../Attractions/ArbreNuageNiwaki.js';
import { ArbresCarres } from '../Attractions/ArbresCarres.js';
import { Autopia } from '../Attractions/Autopia.js';
import { AvengersAssembleFlightForce } from '../Attractions/AvengersAssembleFlightForce.js';
import { AvengersPowerTheNight } from '../Attractions/AvengersPowerTheNight.js';
import { Bambouseraie } from '../Attractions/Bambouseraie.js';
import { BigThunderMountain } from '../Attractions/BigThunderMountain.js';
import { BlancheNeigeEtLesSeptNains } from '../Attractions/BlancheNeigeEtLesSeptNains.js';
import { BuzzLightyearLastBlast } from '../Attractions/BuzzLightyearLastBlast.js';
import { CarsQuatreRouesRally } from '../Attractions/CarsQuatreRouesRally.js';
import { CarsRoadTrip } from '../Attractions/CarsRoadTrip.js';
import { CaseyJrLePetitTrainDuCirque } from '../Attractions/CaseyJrLePetitTrainDuCirque.js';
import { CedrePleureur } from '../Attractions/CedrePleureur.js';
import { CeremonieHeroique } from '../Attractions/CeremonieHeroique.js';
import { CrushSCoaster } from '../Attractions/CrushSCoaster.js';
import { DiscoveryArcade } from '../Attractions/DiscoveryArcade.js';
import { DisneyDreams } from '../Attractions/DisneyDreams.js';
import { DisneyElectricalSkyParade } from '../Attractions/DisneyElectricalSkyParade.js';
import { DisneyStartsOnParade } from '../Attractions/DisneyStartsOnParade.js';
import { DisneyStudio1 } from '../Attractions/DisneyStudio1.js';
import { DisneylandRailroad } from '../Attractions/DisneylandRailroad.js';
import { DumboTheFlyingElephant } from '../Attractions/DumboTheFlyingElephant.js';
import { FevierDAmerique } from '../Attractions/FevierDAmerique.js';
import { FrontierlandPlayground } from '../Attractions/FrontierlandPlayground.js';
import { HeroTrainingCenter } from '../Attractions/HeroTrainingCenter.js';
import { HorseDrawnStreetcarts } from '../Attractions/HorseDrawnStreetcarts.js';
import { IndianaJonesEtLeTempleDuPeril } from '../Attractions/IndianaJonesEtLeTempleDuPeril.js';
import { ItSASmallWorld } from '../Attractions/ItSASmallWorld.js';
import { LAllianceDesAvengers } from '../Attractions/LAllianceDesAvengers.js';
import { LIncroyableSpiderman } from '../Attractions/LIncroyableSpiderman.js';
import { LaCabaneDesRobinson } from '../Attractions/LaCabaneDesRobinson.js';
import { LaGalerieDeLaBelleAuBoisDormant } from '../Attractions/LaGalerieDeLaBelleAuBoisDormant.js';
import { LaPlageDesPirates } from '../Attractions/LaPlageDesPirates.js';
import { LaTaniereDuDragon } from '../Attractions/LaTaniereDuDragon.js';
import { LeCarrouselDeLancelot } from '../Attractions/LeCarrouselDeLancelot.js';
import { LeChateauDeLaBelleAuBoisDormant } from '../Attractions/LeChateauDeLaBelleAuBoisDormant.js';
import { LeGalionDesPirates } from '../Attractions/LeGalionDesPirates.js';
import { LePassageEnchanteDAladdin } from '../Attractions/LePassageEnchanteDAladdin.js';
import { LePaysDesContesDeFees } from '../Attractions/LePaysDesContesDeFees.js';
import { LeRoiLion } from '../Attractions/LeRoiLion.js';
import { LesGardiensDeLaGalaxieDanceChallenge } from '../Attractions/LesGardiensDeLaGalaxieDanceChallenge.js';
import { LesGuerrieresDuWakanda } from '../Attractions/LesGuerrieresDuWakanda.js';
import { LesJardinsFeeriquesDeMainStreetUsa } from '../Attractions/LesJardinsFeeriquesDeMainStreetUsa.js';
import { LesMysteresDuNautilus } from '../Attractions/LesMysteresDuNautilus.js';
import { LesTapisVolantsDAladdin } from '../Attractions/LesTapisVolantsDAladdin.js';
import { LesVoyagesDePinocchio } from '../Attractions/LesVoyagesDePinocchio.js';
import { LibertyArcade } from '../Attractions/LibertyArcade.js';
import { MadHatterSTeaCups } from '../Attractions/MadHatterSTeaCups.js';
import { MainStreetVehicles } from '../Attractions/MainStreetVehicles.js';
import { MeetMickey } from '../Attractions/MeetMickey.js';
import { MickeyAndTheMagician } from '../Attractions/MickeyAndTheMagician.js';
import { MickeyEtSonOrchestrePhilharmagique } from '../Attractions/MickeyEtSonOrchestrePhilharmagique.js';
import { Orbitron } from '../Attractions/Orbitron.js';
import { PavillonDesPrincesses } from '../Attractions/PavillonDesPrincesses.js';
import { PeterPanSFlight } from '../Attractions/PeterPanSFlight.js';
import { PhantomManor } from '../Attractions/PhantomManor.js';
import { PiratesOfCaribbean } from '../Attractions/PiratesOfCaribbean.js';
import { Ratatouille } from '../Attractions/Ratatouille.js';
import { RCRacer } from '../Attractions/RCRacer.js';
import { RustlerRoundupShootin } from '../Attractions/RustlerRoundupShootin.js';
import { SlinkyDogZigzagSpin } from '../Attractions/SlinkyDogZigzagSpin.js';
import { StarTours } from '../Attractions/StarTours.js';
import { StarWarsHyperspaceMountain } from '../Attractions/StarWarsHyperspaceMountain.js';
import { Starport } from '../Attractions/Starport.js';
import { StitchLive } from '../Attractions/StitchLive.js';
import { Tetrapanax } from '../Attractions/Tetrapanax.js';
import { TheDisneyJuniorDreamFactory } from '../Attractions/TheDisneyJuniorDreamFactory.js';
import { TheTwilightZoneTowerOfTerror } from '../Attractions/TheTwilightZoneTowerOfTerror.js';
import { ThunderMesaRiverboatLanding } from '../Attractions/ThunderMesaRiverboatLanding.js';
import { TogetherAPixarMusicalAdventure } from '../Attractions/TogetherAPixarMusicalAdventure.js';
import { Topiaires } from '../Attractions/Topiaires.js';
import { ToySoldiersParachutesDrop } from '../Attractions/ToySoldiersParachutesDrop.js';
import { WebSlingers } from '../Attractions/WebSlingers.js';
import { WollemiaNobilis } from '../Attractions/WollemiaNobilis.js';

export const LogoImgAttraction = (type, slug) => {
    let datas = [];
    if (slug === 'a-million-splashes-of-colours') { datas = AMillionSplashesOfColours(type) }
    if (slug === 'adventure-isle') { datas = AdventureIsle(type) }
    if (slug === 'alice-et-la-reine-de-coeur') { datas = AliceEtLaReineDeCoeur(type) }
    if (slug === 'alice-s-curious-labyrinth') { datas = AliceSCuriousLabyrinth(type) }
    if (slug === 'animation-academy') { datas = AnimationAcademy(type) }
    if (slug === 'animation-celebration-frozen') { datas = AnimationCelebrationFrozen(type) }
    if (slug === 'araucaria-araucana') { datas = AraucariaAraucana(type) }
    if (slug === 'arbre-a-sorcieres') { datas = ArbreASorcieres(type) }
    if (slug === 'arbre-aux-quarante-ecus') { datas = ArbreAuxQuaranteEcus(type) }
    if (slug === 'arbre-nuage-niwaki') { datas = ArbreNuageNiwaki(type) }
    if (slug === 'arbres-carres') { datas = ArbresCarres(type) }
    if (slug === 'autopia') { datas = Autopia(type) }
    if (slug === 'avengers-assemble-flight-force') { datas = AvengersAssembleFlightForce(type) }
    if (slug === 'avengers-power-the-night') { datas = AvengersPowerTheNight(type) }
    if (slug === 'bambouseraie') { datas = Bambouseraie(type) }
    if (slug === 'big-thunder-mountain') { datas = BigThunderMountain(type) }
    if (slug === 'blanche-neige-et-les-sept-nains') { datas = BlancheNeigeEtLesSeptNains(type) }
    if (slug === 'buzz-lightyear-last-blast') { datas = BuzzLightyearLastBlast(type) }
    if (slug === 'cars-quatre-roues-rally') { datas = CarsQuatreRouesRally(type) }
    if (slug === 'cars-road-trip') { datas = CarsRoadTrip(type) }
    if (slug === 'casey-jr-le-petit-train-du-cirque') { datas = CaseyJrLePetitTrainDuCirque(type) }
    if (slug === 'cedre-pleureur') { datas = CedrePleureur(type) }
    if (slug === 'ceremonie-heroique') { datas = CeremonieHeroique(type) }
    if (slug === 'crush-s-coaster') { datas = CrushSCoaster(type) }
    if (slug === 'discovery-arcade') { datas = DiscoveryArcade(type) }
    if (slug === 'disney-dreams') { datas = DisneyDreams(type) }
    if (slug === 'disney-electrical-sky-parade') { datas = DisneyElectricalSkyParade(type) }
    if (slug === 'disney-starts-on-parade') { datas = DisneyStartsOnParade(type) }
    if (slug === 'disney-studio-1') { datas = DisneyStudio1(type) }
    if (slug === 'disneyland-railroad') { datas = DisneylandRailroad(type) }
    if (slug === 'dumbo-the-flying-elephant') { datas = DumboTheFlyingElephant(type) }
    if (slug === 'fevier-d-amerique') { datas = FevierDAmerique(type) }
    if (slug === 'frontierland-playground') { datas = FrontierlandPlayground(type) }
    if (slug === 'hero-training-center') { datas = HeroTrainingCenter(type) }
    if (slug === 'horse-drawn-streetcarts') { datas = HorseDrawnStreetcarts(type) }
    if (slug === 'indiana-jones-et-le-temple-du-peril') { datas = IndianaJonesEtLeTempleDuPeril(type) }
    if (slug === 'it-s-a-small-world') { datas = ItSASmallWorld(type) }
    if (slug === 'l-alliance-des-avengers') { datas = LAllianceDesAvengers(type) }
    if (slug === 'l-incroyable-spiderman') { datas = LIncroyableSpiderman(type) }
    if (slug === 'la-cabane-des-robinson') { datas = LaCabaneDesRobinson(type) }
    if (slug === 'la-galerie-de-la-belle-au-bois-dormant') { datas = LaGalerieDeLaBelleAuBoisDormant(type) }
    if (slug === 'la-plage-des-pirates') { datas = LaPlageDesPirates(type) }
    if (slug === 'la-taniere-du-dragon') { datas = LaTaniereDuDragon(type) }
    if (slug === 'le-carrousel-de-lancelot') { datas = LeCarrouselDeLancelot(type) }
    if (slug === 'le-chateau-de-la-belle-au-bois-dormant') { datas = LeChateauDeLaBelleAuBoisDormant(type) }
    if (slug === 'le-galion-des-pirates') { datas = LeGalionDesPirates(type) }
    if (slug === 'le-passage-enchante-d-aladdin') { datas = LePassageEnchanteDAladdin(type) }
    if (slug === 'le-pays-des-contes-de-fees') { datas = LePaysDesContesDeFees(type) }
    if (slug === 'le-roi-lion') { datas = LeRoiLion(type) }
    if (slug === 'les-gardiens-de-la-galaxie-dance-challenge') { datas = LesGardiensDeLaGalaxieDanceChallenge(type) }
    if (slug === 'les-guerrieres-du-wakanda') { datas = LesGuerrieresDuWakanda(type) }
    if (slug === 'les-jardins-feeriques-de-main-street-usa') { datas = LesJardinsFeeriquesDeMainStreetUsa(type) }
    if (slug === 'les-mysteres-du-nautilus') { datas = LesMysteresDuNautilus(type) }
    if (slug === 'les-tapis-volants-d-aladdin') { datas = LesTapisVolantsDAladdin(type) }
    if (slug === 'les-voyages-de-pinocchio') { datas = LesVoyagesDePinocchio(type) }
    if (slug === 'liberty-arcade') { datas = LibertyArcade(type) }
    if (slug === 'mad-hatter-s-tea-cups') { datas = MadHatterSTeaCups(type) }
    if (slug === 'main-street-vehicles') { datas = MainStreetVehicles(type) }
    if (slug === 'meet-mickey') { datas = MeetMickey(type) }
    if (slug === 'mickey-and-the-magician') { datas = MickeyAndTheMagician(type) }
    if (slug === 'mickey-et-son-orchestre-philharmagique') { datas = MickeyEtSonOrchestrePhilharmagique(type) }
    if (slug === 'orbitron') { datas = Orbitron(type) }
    if (slug === 'pavillon-des-princesses') { datas = PavillonDesPrincesses(type) }
    if (slug === 'peter-pan-s-flight') {  datas = PeterPanSFlight(type) }
    if (slug === 'phantom-manor') { datas = PhantomManor(type) }
    if (slug === 'pirates-of-caribbean') { datas = PiratesOfCaribbean(type) }
    if (slug === 'ratatouille') { datas = Ratatouille(type) }
    if (slug === 'rc-racer') { datas = RCRacer(type) }
    if (slug === 'rustler-roundup-shootin') { datas = RustlerRoundupShootin(type) }
    if (slug === 'slinky-dog-zigzag-spin') { datas = SlinkyDogZigzagSpin(type) }
    if (slug === 'star-tours') { datas = StarTours(type); }
    if (slug === 'star-wars-hyperspace-mountain') { datas = StarWarsHyperspaceMountain(type) }
    if (slug === 'starport') { datas = Starport(type) }
    if (slug === 'stitch-live') { datas = StitchLive(type) }
    if (slug === 'tetrapanax') { datas = Tetrapanax(type) }
    if (slug === 'the-disney-junior-dream-factory') { datas = TheDisneyJuniorDreamFactory(type) }
    if (slug === 'the-twilight-zone-tower-of-terror') { datas = TheTwilightZoneTowerOfTerror(type) }
    if (slug === 'thunder-mesa-riverboat-landing') { datas = ThunderMesaRiverboatLanding(type) }
    if (slug === 'together-a-pixar-musical-adventure') { datas = TogetherAPixarMusicalAdventure(type) }
    if (slug === 'topiaires') { datas = Topiaires(type) }
    if (slug === 'toy-soldiers-parachutes-drop') { datas = ToySoldiersParachutesDrop(type) }
    if (slug === 'web-slingers') { datas = WebSlingers(type) }
    if (slug === 'wollemia-nobilis') { datas = WollemiaNobilis(type) }
    return datas.length > 0 ? datas : LogoImgDefault(type);}
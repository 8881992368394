import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LogoImgDefault } from '../../Files/Logos/LogoImgDefault.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { InfosCaracter } from '../../Components/Public/Blocks/InfosCaracter.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';

export const Caracter = (props) => {
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [caracter, setCaracter] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataCaracter = await fetch(`${hosting}/caracter?id=${id}`);
            const caracter = await dataCaracter.json();
            setCaracter(caracter);
        };
        fetchtheData();
    }, [hosting, id]);
    let items = {};
    const collection = [];
    const logoImgDefault = LogoImgDefault('img');
    for (let i = 0; i < caracter.length; i++) {
        collection.push({
            name: caracter[i].aname,
            href: caracter[i].aroute,
            images: logoImgDefault,
        });
    }
    return (
        <div className="caracter">
            <Carrousel label="caracter" datas={collection} total={logoImgDefault.length} />
            <BreadCrumb />
            <section className="infos">
                {caracter.map((item, i) => {
                    items = item;
                    return (
                        <div className="bloc-infos" key={`i-${i}`}>
                            <BlockLogo label="caracter" item={item} />
                            <InfosCaracter datas={caracter} />
                        </div>
                    )
                })}
                <div className="description">
                    <BlockTitle label="caracter" item={items} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
        </div>
    )
}
import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../Routes/HostBackend.js';
import { FilterBkgColor } from '../Filters/FilterBkgColor.js';
import { GetTable } from '../Functions/GetTable.js';
import { GetUpperCase } from '../Functions/GetUpperCase.js';

export const Finder = () => {
    const hosting = HostBackend();
    const uri = window.location.href;
    const hash = sessionStorage.getItem('token');
    const bkgNav = FilterBkgColor();
    const entity = GetTable();
    let table = entity.table;
    let label = entity.label;
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [find, setFind] = useState('');
    const [result, setResult] = useState([]);
    useEffect(() => {
        if (find.length > 0) {
            const fetchtheData = async () => {
                const dataResult = await fetch(`${hosting}/find?ent=${table}&find=${find}`);
                const result = await dataResult.json();
                setResult(result);
            };
            fetchtheData();
        }
    }, [hosting, table, find]);
    if (!uri.includes('connexion') && !uri.includes('dashboard')) {
        let searchLabel = GetUpperCase(label);
        return (
            <div className={`finder ${bkgNav}`}>
                <div className="label" onClick={(e) => { show0 === 'hide' ? setShow0('show') : setShow0('hide'); setShow1('hide'); }}>
                    <div>{searchLabel}</div>
                    <i className="fa fa-search"></i>
                </div>
                <div className={`search ${show0}`}>
                    <input type="text" max="50" value={find} name="find" onChange={(e) => { setFind(e.target.value); setShow1('show'); }} />
                </div>
                <div className={`result ${show1}`}>
                    <div className="items">
                        {result.map((el, i) => {
                            const name = (table === 'administrator' | table === 'user') ? el.lastname : el.name;
                            const href_admin = `/admin/entity/update/${table}/${el.id}`;
                            const href_public = el.route;
                            const href = (uri.includes('admin') && hash !== null) ? href_admin : href_public;
                            return (
                                <a className="search-link" key={`f-${i}`} href={href}>{name}</a>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}
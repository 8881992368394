import { BlockLink } from './BlockLink.js'
import { LogoParks } from '../../../Files/Logos/LogoParks.js';
import { LogoUnivers } from '../../../Files/Logos/LogoUnivers.js';
import { LogoCaracter } from '../../../Files/Logos/LogoCaracter.js';
import { LogoImgAttraction } from '../../../Files/Logos/LogoImgAttraction.js';
import { LogoImgOrigin } from '../../../Files/Logos/LogoImgOrigin.js';
import { GetStringTotal } from '../Functions/GetStringTotal.js';

export const BlockLogo = (props) => {
    const label = props.label;
    const item = props.item;
    let logo;
    switch (label) {
        case 'park':
        case 'home':
        case 'categories':
        default:
            logo = LogoParks(item.pslug);
            return (
                <div className="logo">
                    <a className="icon" href={item.proute} alt={item.pname} title={item.pname}>
                        <img className="img" src={logo} title={item.pname} alt={item.pname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.pname}</div>
                    </div>
                </div>
            )
        case 'univers':
            logo = LogoUnivers(item.uslug);
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.uname} alt={item.uname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.uname}</div>
                    </div>
                </div>
            )
        case 'attraction':
            logo = LogoImgAttraction('icon', item.aslug)
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <BlockLink href={`/categorie/${item.cslug}`} name={item.cname} link={true} />
                        <BlockLink href={`/origine/${item.oslug}`} name={item.oname} link={true} />
                    </div>
                </div>
            )
        case 'caracters':
            logo = LogoCaracter(item.aslug);
            return (
                <div className="logo">
                    <a className="icon" href={item.aroute} alt={item.aname} title={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <div className="created">{item.created.split('-')[0]}</div>
                        <BlockLink href={item.proute} name={item.pname} link={true} />
                        <BlockLink href={item.oroute} name={item.oname} date={item.ocreated.split('-')[0]} />
                    </div>
                </div>
            )
        case 'caracter':
            logo = LogoCaracter(item.slug);
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.name}</div>
                        <BlockLink href={'/personnages'} name={'Personnages'} link={true} />
                        <BlockLink href={item.proute} name={item.pname} link={true} />
                        <BlockLink href={item.oroute} name={item.oname} link={true} />
                    </div>
                </div>
            )
        case 'origins':
            logo = LogoImgOrigin('icon', item.oslug);
            return (
                <div className="logo">
                    <a className="icon" href={item.oroute} alt={item.oname} title={item.oname}>
                        <img className="img" src={logo} title={item.oname} alt={item.oname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.oname}</div>
                        <div className="name">{GetStringTotal('attraction', props.tt_ori)}</div>
                        <div className="name">{GetStringTotal('personnage', props.tt_cat)}</div>
                    </div>
                </div>
            )
            case 'origin':
            logo = (item.cname) ? LogoImgAttraction('icon', item.aslug) : LogoCaracter(item.aslug);
            const cname = (item.cname) ? item.cname : 'Personnages';
            const croute = (item.cname) ? `/categorie/${item.cslug}` : '/personnages';
            return (
                <div className="logo">
                    <a className="icon" href={item.aroute} alt={item.aname} title={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <BlockLink href={item.proute} name={item.pname} link={true} />
                        <BlockLink href={croute} name={cname} link={true} />
                    </div>
                </div>
            )
        case 'category':
            logo = LogoImgAttraction('icon', item.aslug)
            return (
                <div className="logo">
                    <a className="icon" href={item.aroute} alt={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <div className="created">{item.created.split('-')[0]}</div>
                        <BlockLink href={item.uroute} name={item.uname} link={true} />
                        <BlockLink href={item.oroute} name={item.oname} link={true} />
                    </div>
                </div>
            )
        case 'experiment':
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={item.href} title={item.name} alt={item.name} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title-link">{item.code}</div>
                        <div className="title">{item.name}</div>
                    </div>
                </div>
            )
    }
}
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Caracter } from '../../Pages/Public/Caracter.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteCaracter = () => {
    const hosting = HostBackend();
    const [navCaracters, setNavCaracters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavCaracters = await fetch(`${hosting}/nav_caracters`);
            const navCaracters = await dataNavCaracters.json();
            setNavCaracters(navCaracters);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                {navCaracters.map((el, i) => {
                    return (<Route path={el.route} element={<Caracter id={el.id} slug={el.slug} />} key={`c-${i}`} />)
                })}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
import { BlockLabel } from './BlockLabel.js';
import { BlockPrivileges } from './BlockPrivileges.js';

export const InfosCaracter = (props) => {
    const datas = props.datas;
    return (
        datas.map((a,i) => {
            return (
                <div key={`a-${i}`} className="list">
                    <BlockPrivileges label="photopass" icon="fa-camera" name={a.photopass} description={a.photopass_description} />
                    <BlockPrivileges label="pmr" icon="fa-wheelchair" name={a.pmr} description={a.pmr_description} />
                    <BlockLabel label="Categorie" icon="fa fa-cogs" name={'Personnage'} />
                    <BlockLabel label="Public" icon="fa fa-users" name={a.public} />
                </div>
            )
        })
    )
}



import { useNavigate } from 'react-router-dom';
import { CleanAdminSession } from '../../../Admin/Functions/CleanAdminSession.js';

export const ButtonLogout = () => {
    const navigate = useNavigate();
    return (
        <button className="logout" onClick={() => {
            CleanAdminSession();
            navigate('/admin/connexion');
        }}>
            <i className="fa fa-power-off"></i>
        </button>
    )
}
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Caracters } from '../../Pages/Public/Caracters.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteCaracters = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/personnages" element={<Caracters />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Caracters = () => {
    const hosting = HostBackend();
    const [caracters, setCaracters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const caractersDatas = await fetch(`${hosting}/caracters`);
            const caracters = await caractersDatas.json();
            setCaracters(caracters);
        };
        fetchData();
    }, [hosting]);
    let items = {
        created: '1992-04-12',
        author: 'Walt Disney Imagineering',
        name: 'Personnages du\nParc Disneyland Paris',
        link_name: 'Wikipedia',
        link_href: 'https://wikipedia.com'
    };
    const str_total = GetStringTotal('personnage', caracters.length);
    return (
        <div className="origins">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {caracters.map((item, i) => {
                        items['description'] = item.description;
                        return (
                            <BlockLogo label="caracters" item={item} key={`i-${i}`} />
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="caracters" item={items} total={str_total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
        </div>
    )
}

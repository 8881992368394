/* datas */
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterOrigins } from '../../Components/Public/Filters/FilterOrigins.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Jumbotron } from '../../Components/Public/Document/Jumbotron.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Categories = (props) => {
    let univers, attractions;
    const parks = props.parks;
    const attraction = props.att;
    const caracters = props.car;
    const origins = FilterOrigins('home', props.att);
    const categories = FilterCategory('home', props.att);
    const datas = [
        { total: GetStringTotal('parc', parks.length) },
        { total: GetStringTotal('univers', parks[0] ? parks[0].univers.length + parks[1].univers.length : 0) },
        { total: GetStringTotal('attraction', attraction.length) },
        { total: GetStringTotal('personnage', caracters.length) },
        { total: GetStringTotal('origine', origins.length) },
    ]
    return (
        <div className="categories">
            <Jumbotron datas={datas} title={'Disneyland'} sub={'Paris'} />
            <BreadCrumb />
            <section className="infos">
                <div className="bloc-infos">
                    <div className="list">
                        <BlockCollection label="categories" datas={categories} attractions={attraction} />
                    </div>
                </div>
                {parks.map((item, i) => {
                    if (item.pid === 1) {
                        univers = parks[0].univers;
                        attractions = FilterAttractions(attraction, item.pid);
                    } else {
                        univers = parks[1].univers;
                        attractions = FilterAttractions(attraction, item.pid);
                    }
                    return (
                        <div className="bloc-infos" key={`c-${i}`}>
                            <BlockLogo label="categories" item={item} />
                            <BlockCollection label="park" datas={univers} attractions={attractions} />
                        </div>
                    )
                })}
            </section>
        </div>
    )
}

export const CountDatas = (label, datas) => {
    const counter = {};
    if (label === 'univers') {
        for (const el of datas) {
            counter[el.uslug] = counter[el.uslug] ? counter[el.uslug] + 1 : 1;
        }
    }
    if (label === 'category') {
        for (const el of datas) {
            counter[el.cslug] = counter[el.cslug] ? counter[el.cslug] + 1 : 1;
        }
    }
    if (label === 'caracter') {
        for (const el of datas) {
            counter[el.oslug] = counter[el.oslug] ? counter[el.oslug] + 1 : 1;
        }
    }
    if (label === 'origin') {
        for (const el of datas) {
            counter[el.oslug] = counter[el.oslug] ? counter[el.oslug] + 1 : 1;
        }
    }
    return counter;
}
import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LogoImgOrigin } from '../../Files/Logos/LogoImgOrigin.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';

export const Origin = (props) => {
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [origin, setOrigin] = useState([]);
    const [attractions, setAttractions] = useState([]);
    const [caracters, setCaracters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasOrigin = await fetch(`${hosting}/origin?id=${id}`);
            const origin = await datasOrigin.json();
            setOrigin(origin);
            const datasAttractions = await fetch(`${hosting}/attractions_by_origin?id=${id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
            const datasCaracters = await fetch(`${hosting}/caracters_by_origin?id=${id}`);
            const caracters = await datasCaracters.json();
            setCaracters(caracters);
        };
        fetchData();
    }, [hosting, id]);
    let items = {};
    for (let i = 0; i < origin.length; i++) {
        items = {
            created: origin[i].created,
            author: origin[i].author,
            name: origin[i].name,
            description: origin[i].description,
            slug: origin[i].slug,
            link_name: origin[i].link_name,
            link_href: origin[i].link_href
        };
    }
    const str_total = GetStringTotal('attraction', attractions.length + caracters.length);
    const collection = [];
    let total = 0;
    collection.push({
        slug: items.slug,
        name: items.name,
        images: LogoImgOrigin('img', items.slug)
    });
    total = LogoImgOrigin('img', items.slug).length;
    return (
        <div className="origin">
            <Carrousel label="origin" datas={collection} total={total} />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {attractions.map((item, i) => {
                        return (
                            <BlockLogo key={`a-${i}`} label="origin" item={item} />
                        )
                    })}
                    {caracters.map((item, i) => {
                        return (
                            <BlockLogo key={`c-${i}`} label="origin" item={item} />
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="origin" item={items} total={str_total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
        </div>
    )
}
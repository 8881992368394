export const GetStringTotal = (label, total) => {
    let string = '';
    if (total === 1) {
        string = `${total} ${label}`;
    } else if (total > 1) {
        if (label === 'univers' | label === 'Misterious') {
            string = `${total} ${label}`;
        } else {
            string = `${total} ${label}s`;
        }
    }
    return string;
}
import { NavPublic } from '../Blocks/NavPublic';
import { NavAdmin } from '../Blocks/NavAdmin';

export const Navigation = () => {
    const uri = window.location.href;
    const token = sessionStorage.getItem('token');
    if (uri.includes('admin/') && token) {
        return (<NavAdmin />)
    } else {
        return (<NavPublic />);
    }
}
import './assets/css/app.min.css';
/* components */
import { Header } from './Components/Public/Document/Header.js';
import { Navigation } from './Components/Public/Document/Navigation.js';
import { Finder } from './Components/Public/Document/Finder.js';
import { BackTop } from './Components/Public/Document/BackTop.js';
import { Footer } from './Components/Public/Document/Footer.js';
import { LoadTitlePage } from './Components/Public/Functions/LoadTitlePage.js';
/* routes */
import { Router } from './Routes/Router.js';

function App() {
    LoadTitlePage();
    const uri = window.location.href;
    const main = uri.includes('admin') ? 'admin' : 'public';
    return (
        <div id="main" className={`main ${main}`}>
            <Header />
            <Navigation />
            <Finder />
            <Router />
            <BackTop />
            <Footer />
        </div>
    );
}

export default App;

import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";

export const Entities = (props) => {
    const entity = props.entity;
    const datas = props.datas;
    switch (entity) {
        case 'administrator':
        case 'user':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`u${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{el.lastname}</div>
                            <div className="item">{el.firstname} ({el.id})</div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                })
            )
        case 'attraction':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`a${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{el.name} <span>({el.id})</span></div>
                            <div className="item">{el.pname}</div>
                            <div className="item">{el.uname}</div>
                            <div className="item">{el.cname}</div>
                            <div className="item">{el.oname}</div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                })
            )
        case 'caracter':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`a${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{el.name} <span>({el.id})</span></div>
                            <div className="item">{el.pname}</div>
                            <div className="item">{el.oname}</div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                })
            )
        case 'park':
        case 'univers':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`p${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{el.name} <span>({el.id})</span></div>
                            <div className="item">{el.route}</div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                }))
        case 'outlinks':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`o${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{el.name} <span>({el.id})</span></div>
                            <div className="item">{el.link}</div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                }))
        case 'origin':
        case 'category':
            return (
                datas.map((el,i) => {
                    return (
                        <div key={`e${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="item">{el.name} <span>({el.id})</span></div>
                            <ButtonDelete id={el.id} entity={entity} />
                            <ButtonUpdate id={el.id} entity={entity} />
                        </div>
                    )
                }));
        default:
            return (
                <></>
            )

    }
}

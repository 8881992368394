import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LoadExperiments } from '../../Files/Datas/LoadExperiments.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BlockExperiments } from '../../Components/Public/Blocks/BlockExperiments.js';

export const Experiments = () => {
    const hosting = HostBackend();
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasBody = await fetch(`${hosting}/experiments`);
            const body = await datasBody.json();
            setBody(body);
        };
        fetchData();
    }, [hosting]);
    const experiments = LoadExperiments();
    const total = GetStringTotal('expérience', experiments.total);
    const items = experiments.items;
    if (body && body[0]) {
        items['description'] = body[0].description;
        items['link_name'] = body[0].link_name;
        items['link_href'] = body[0].link_href;
    }
    return (
        <div className="experiments">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {experiments.datas.map((exp, i) => {
                        let total = exp.data.length;
                        return (
                            <div className="list" key={`b-${i}`}>
                                <div className="title-list">{`SERIE ${i}00 - expériences (${total})`}</div>
                                {exp.data.map((path, i) => {
                                    let str = path.split('/')[3].split('.')[0];
                                    let code = str.split('-')[0];
                                    let name = str.slice(4, str.length);
                                    let item = { href: path, code: code, name: name };
                                    return (
                                        <BlockLogo label="experiment" item={item} key={`i-${i}`} />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="experiment" item={items} total={total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                    <BlockExperiments exp={experiments} />
                </div>
            </section>
        </div>
    )
}

/* route public */
import { RouteHome } from './Public/RouteHome.js';
import { RouteParks } from './Public/RouteParks.js';
import { RouteUnivers } from './Public/RouteUnivers.js';
import { RouteAttractions } from './Public/RouteAttractions.js';
import { RouteCategories } from './Public/RouteCategories.js';
import { RouteCategory } from './Public/RouteCategory.js';
import { RouteCaracters } from './Public/RouteCaracters.js';
import { RouteCaracter } from './Public/RouteCaracter.js';
import { RouteOrigins } from './Public/RouteOrigins.js';
import { RouteOrigin } from './Public/RouteOrigin.js';
import { RouteOutlinks } from './Public/RouteOutlinks.js';
import { RouteExperiments} from './Public/RouteExperiments.js';
/* route admin */
import { RouteLogin } from './Admin/RouteLogin.js';
import { RouteCollection } from './Admin/RouteCollection.js';
import { RouteInsert } from './Admin/RouteInsert.js';
import { RouteUpdate } from './Admin/RouteUpdate.js';

export const Router = () => {
    const uri = window.location.href;
    /* admin */
    if (uri.includes('/admin/connexion')) return (<RouteLogin />);
    if (uri.includes('/admin/entity/collection')) return (<RouteCollection />);
    if (uri.includes('/admin/entity/insert')) return (<RouteInsert />);
    if (uri.includes('/admin/entity/update')) return (<RouteUpdate />);
    /* public */
    if (uri.includes('/categories')) return(<RouteCategories />);
    if (uri.includes('/categorie')) return(<RouteCategory />);
    if (uri.includes('/origines')) return(<RouteOrigins />);
    if (uri.includes('/origine')) return(<RouteOrigin />);
    if (uri.includes('/personnages')) return(<RouteCaracters />);
    if (uri.includes('/attraction')) return (<RouteAttractions />);
    if (uri.includes('/personnage')) return(<RouteCaracter />);
    if (uri.includes('/univers')) return (<RouteUnivers />);
    if (uri.includes('/park')) return (<RouteParks />);
    if (uri.includes('/experiences')) return(<RouteExperiments />);
    if (uri.includes('/liens')) return(<RouteOutlinks />);
    if (uri.includes('/')) return (<RouteHome />);

}
export const Card = (props) => {
    const label = props.label;
    let name;
    switch (label) {
        case 'home':
        case 'park':
        case 'univers':
        default:
            return (
                props.datas.map((item, i) => {
                    return (
                        <a className="card" href={item.href} alt={item.name} title={item.name} key={`i-${i}`}>
                            <div className="icon">
                                <img className="img" src={item.images} title={item.name} alt={item.name} loading="lazy" />
                            </div>
                            <div className="legend">{item.name}</div>
                        </a>
                    )
                })
            )
        case 'attraction':
        case 'origin':
        case 'caracter':
        case 'outlinks':
            return (
                props.datas.map((item) => {
                    return (
                        item.images.map((src, i) => {
                            if (label === 'caracter' | label === 'outlinks') {
                                name = 'Steamboat Willie (1928)';
                            } else {
                                name = `${item.name} - ${i+1}`;
                            }
                            return (
                                <div className="card" key={`i-${i}`}>
                                    <div className="icon">
                                        <img className="img" src={src} title={name} alt={name} loading="lazy" />
                                    </div>
                                    <div className="legend">{name}</div>
                                </div>
                            )
                        })
                    )
                })
            )
    }
}
import { CountDatas } from '../Functions/CountDatas.js';
import { SortASC } from '../Functions/SortASC.js';
import { GetIconCode } from '../Functions/GetIconCode.js';
import { GetStringTotal } from '../Functions/GetStringTotal.js';

export const BlockCollection = (props) => {
    const count_univers = props.attractions ? CountDatas('univers', props.attractions) : [];
    const count_category = props.attractions ? CountDatas('category', props.attractions) : [];
    const count_origin = props.attractions ? CountDatas('origin', props.attractions) : [];
    const count_caracter = props.caracters ? CountDatas('caracter', props.caracters) : [];
    const datas = props.datas ? SortASC(props.datas) : [];
    const total = props.datas ? props.datas.length : 0;
    switch (props.label) {
        case 'home':
        case 'park':
            return (
                <div className="list">
                    <div className="total">{GetStringTotal('univers', total)}</div>
                    {datas.map((el, i) => {
                        return (
                            <a className="link" href={el.uroute} alt={el.uname} title={el.uname} key={`i-${i}`}>
                                <div className="name">
                                    <div>{el.uname}</div>
                                    <div className="note">{GetStringTotal('attraction', count_univers[el.uslug])}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'univers':
        case 'attractions':
            return (
                <div className="list">
                    <div className="total">{GetStringTotal('attraction', total)}</div>
                    {datas.map((el, i) => {
                        return (
                            <a className="link" href={el.aroute} alt={el.aname} title={el.aname} key={`i-${i}`}>
                                <div className="code"><i className={GetIconCode(el.ccode)}></i></div>
                                <div className="name">{el.aname}</div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'categories':
            return (
                <div className="list">
                    <div className="total">{GetStringTotal('catégorie', total)}</div>
                    {datas.map((el, i) => {
                        return (
                            <a className="link" href={el.croute} alt={el.cname} title={el.cname} key={`i-${i}`}>
                                <div className="code" title={el.cname}><i className={GetIconCode(el.code)}></i></div>
                                <div className="name">
                                    <div>{el.cname}</div>
                                    <div className="note">{GetStringTotal('attraction', count_category[el.cslug])}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'origins':
            return (
                <div className="list">
                    <div className="total">{GetStringTotal('origine', total)}</div>
                    {datas.map((el, i) => {
                        return (
                            <a className="link" href={el.oroute} alt={el.oname} title={el.oname} key={`i-${i}`}>
                                <div className="date">{`${el.ocreated.split('-')[0]}`}</div>
                                <div className="name">
                                    <div>{el.oname}</div>
                                    <div className="note">{GetStringTotal('attraction', count_origin[el.oslug])}</div>
                                    <div className="note">{GetStringTotal('personnage', count_caracter[el.oslug])}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        default:
            return (
                <></>
            )
    }
}
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Attraction } from '../../Pages/Public/Attraction.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteAttractions = () => {
    const hosting = HostBackend();
    const [navAttractions, setNavAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav_attractions`);
            const navAttractions = await dataNavAttractions.json();
            setNavAttractions(navAttractions);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                {navAttractions.map((el, i) => {
                    return (<Route path={el.aroute} element={<Attraction id={el.aid} slug={el.aslug} />} key={`a-${i}`} />)
                })}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
import { FormAdminInsert } from '../../Components/Admin/Form/FormAdminInsert.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const Insert = (props) => {
    const entity = props.entity;
    window.addEventListener('load', (ev) => {
        AutoComplete();
    });
    return (
        <div className="admin">
            <FormAdminInsert entity={entity} />
            <a className="back" href={`/admin/entity/collection/${entity}`} alt="back" title="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}